<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FEATURES } from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('client', ['hasFeature']),
  },
  created() {
    if (this.hasFeature(FEATURES.TRACKING)) {
      this.initTracking()
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions('tracking', ['initTracking']),
  },
}
</script>

<style></style>
