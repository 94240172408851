import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import trackingIcons from './trackingIcons'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    backTo: {
      type: String,
      default: '/tracking',
    },
  },
  computed: {
    ...mapGetters('tracking', ['trackingInitLoading', 'trackerByType']),
    tracker() {
      return this.trackerByType(this.type)
    },
    isConnected() {
      return _get(this.tracker, 'isConnected')
    },
    lastUpdated() {
      return this.tracker.latestDate
        ? format(parseISO(this.tracker.latestDate), 'M/d/yyyy')
        : '--'
    },
    icon() {
      return trackingIcons[this.type]
    },
  },
}
