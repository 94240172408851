<template>
  <div
    class="w-screen max-w-lg h-full bg-white overflow-auto border-l flex flex-col"
  >
    <div class="flex items-center p-20 border-b border-grey-200">
      <h4 class="flex-1 mr-100">
        {{
          $t('tracking.edit_drawer.title', [
            $t(`tracking.lingo.${tracker.type}.display`),
          ])
        }}
      </h4>
      <button @click="close">
        <CloseIcon />
      </button>
    </div>
    <div class="px-30 pt-20 flex flex-col flex-1 h-0 overflow-y-auto">
      <div class="flex-1">
        <div v-if="isBloodPressure" class="flex items-center">
          <FormInput
            v-model="systolic"
            class="w-150"
            name="systolic-value"
            :label="$t('tracking.edit_drawer.systolic')"
            :placeholder="$t(`tracking.lingo.${tracker.type}.units`)"
          />
          <h3 class="mt-20 mx-12 text-grey-500">/</h3>
          <FormInput
            v-model="diastolic"
            class="w-150"
            name="diastolic-value"
            :label="$t('tracking.edit_drawer.diastolic')"
            :placeholder="$t(`tracking.lingo.${tracker.type}.units`)"
          />
        </div>
        <div v-else>
          <FormInput
            v-model="value"
            class="w-200"
            name="tracker-value"
            :label="$t(`tracking.lingo.${tracker.type}.display`)"
            :placeholder="$t(`tracking.lingo.${tracker.type}.units`)"
          />
        </div>
      </div>
      <div class="p-20 flex justify-end">
        <HMButton
          rounded="full"
          color="white"
          size="x-small"
          outlined
          class="mr-20"
          @click="close"
        >
          {{ $t('common.cancel_btn') }}
        </HMButton>
        <HMButton
          :disabled="!isValid || trackingUpdateLoading"
          size="x-small"
          rounded="full"
          @click="submit"
        >
          {{ $t('tracking.edit_drawer.confirm_btn') }}
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@components/common/CloseIcon'
import { FormInput } from '@components/common/forms'
import updateTrackerMixin from '@platform-shared/mixins/tracking/updateTracker'

export default {
  components: { CloseIcon, FormInput },
  mixins: [updateTrackerMixin],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
