import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('tracking', ['humanApiToken']),
  },
  methods: {
    ...mapActions('tracking', ['setHumanApiToken']),
    openHumanApi() {
      const { memberId, humanApiClientId, publicToken } = this.humanApiToken

      window.HumanConnect.open({
        clientUserId: memberId,
        clientId: humanApiClientId,
        publicToken,
        modal: 1,

        finish: (err, sessionTokenObject) => {
          this.setHumanApiToken({
            humanId: sessionTokenObject.humanId,
            sessionToken: sessionTokenObject.sessionToken,
          })
        },
        close() {
          /* optional callback */
        },
        error() {
          /*
          Optional callback called if an error occurs when loading
          the popup.
          `err` has fields: `code`, `message`, `detailedMessage`
          */
        },
      })
    },
  },
}
