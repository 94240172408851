import { mapGetters } from 'vuex'
import _last from 'lodash/last'

export default {
  computed: {
    ...mapGetters('tracking', ['trackers']),
  },
  methods: {
    isLast(tracker) {
      return tracker === _last(this.trackers)
    },
  },
}
