import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    tracker: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getTrackerHistory({
      trackerKey: this.tracker.typeCode,
      trackerType: this.tracker.type,
    })
  },
  computed: {
    ...mapGetters('tracking', ['historyByTracker']),
    history() {
      return this.historyByTracker(this.tracker.type)
    },
  },
  methods: {
    ...mapActions('tracking', ['getTrackerHistory']),
  },
}
