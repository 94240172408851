import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/format'
import _includes from 'lodash/includes'
import { TRACKER_TYPES } from '@platform-shared/constants'

export default {
  props: {
    tracker: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: '',
      systolic: '',
      diastolic: '',
    }
  },
  computed: {
    ...mapGetters('tracking', ['trackingUpdateLoading']),
    isBloodPressure() {
      return this.tracker.typeCode === TRACKER_TYPES.bloodPressure
    },
    formattedValue() {
      switch (this.tracker.typeCode) {
        case TRACKER_TYPES.bloodPressure:
          return { systolic: this.systolic, diastolic: this.diastolic }
        case TRACKER_TYPES.sleep:
          return this.value * 60
        default:
          return this.value
      }
    },
    isValid() {
      return this.isBloodPressure
        ? this.systolic !== '' && this.diastolic !== ''
        : this.value !== ''
    },
  },
  methods: {
    ...mapActions('tracking', ['updateTracker']),
    submit() {
      const dateFormat = _includes(
        [TRACKER_TYPES.weight, TRACKER_TYPES.bloodPressure],
        this.tracker.typeCode
      )
        ? "yyyy-MM-dd'T'HH:mm:ss"
        : "yyyy-MM-dd'T00:00:00'"

      this.updateTracker({
        value: this.formattedValue,
        date: format(new Date(), dateFormat),
        source: 'manual',
        data: {},
        tracker: this.tracker.typeCode,
      }).then(this.close)
    },
  },
}
