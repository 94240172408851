<template>
  <div class="w-full max-w-3xl py-20 mb-200">
    <Spinner v-if="trackingInitLoading" />
    <div v-else-if="tracker && !trackingInitLoading">
      <Back
        :route="backTo"
        :title="
          backTo === '/'
            ? 'common.back_to_home'
            : 'tracking.details.back_to_trackers'
        "
        class="my-20"
      />
      <div class="flex mb-20 items-center">
        <div
          v-if="icon"
          class="h-45 w-45 rounded-full flex justify-center items-center mr-15 bg-plum-100"
        >
          <Icon :iconClass="`${icon.type} text-plum-500 flex text-2xl`" />
        </div>
        <h2>{{ $t(`tracking.lingo.${tracker.type}.display`) }}</h2>
      </div>
      <div class="rounded-lg border border-grey-300 p-25">
        <div class="flex flex-col md:flex-row items-start">
          <div class="flex-grow">
            <div class="flex">
              <div
                v-if="isConnected"
                class="flex items-center justify-center rounded-sm px-10 py-3 font-semibold capitalize bg-blue-500 text-white"
              >
                <Icon iconClass="icon-charger" />
                <span class="ml-5 mb-2 text-sm">{{
                  $t('tracking.connected')
                }}</span>
              </div>
              <p :class="{ 'ml-15': isConnected }">
                {{ $t('tracking.details.last_updated') }}: {{ lastUpdated }}
              </p>
            </div>
            <p class="text-xl mt-15 tracking-wide">
              {{ $t(`tracking.lingo.${tracker.type}.display`) }}:
              <strong>{{ tracker.todaysDisplayValue }}</strong>
              <span v-if="tracker.recordedToday">
                {{ $t(`tracking.lingo.${tracker.type}.units`) }}</span
              >
            </p>
          </div>
          <HMButton
            rounded="full"
            fontWeight="semibold"
            class="w-full mt-20 md:w-150 md:mt-0"
            size="x-small"
            @click="showUpdateDrawer = true"
            >{{ $t('tracking.details.track') }}</HMButton
          >
          <HMButton
            color="white"
            outlined
            rounded="full"
            fontWeight="semibold"
            padding="tight"
            class="w-full mt-10 md:w-150 md:ml-20 md:mt-0"
            size="x-small"
            @click="showHistoryDrawer = true"
          >
            {{ $t('tracking.details.view_history') }}
          </HMButton>
        </div>
      </div>
      <div class="pt-15 leading-snug">
        <Markdown
          :source="$t(`tracking.lingo.${tracker.type}.summary`)"
          :anchorAttributes="{ target: '_blank' }"
        />
      </div>
    </div>
    <Drawer v-model="showUpdateDrawer">
      <UpdateTrackerDrawer
        :tracker="tracker"
        @close="showUpdateDrawer = false"
      />
    </Drawer>
    <Drawer v-model="showHistoryDrawer">
      <TrackerHistoryDrawer
        :tracker="tracker"
        @close="showHistoryDrawer = false"
      />
    </Drawer>
  </div>
</template>
<script>
import Spinner from '@components/common/Spinner'
import Markdown from '@components/common/Markdown'
import Drawer from '@components/common/Drawer'
import UpdateTrackerDrawer from './UpdateTrackerDrawer'
import TrackerHistoryDrawer from './TrackerHistoryDrawer'
import trackingDetailMixin from '@platform-shared/mixins/tracking/trackingDetail'

export default {
  components: {
    Markdown,
    Spinner,
    Drawer,
    UpdateTrackerDrawer,
    TrackerHistoryDrawer,
  },
  mixins: [trackingDetailMixin],
  data() {
    return {
      showUpdateDrawer: false,
      showHistoryDrawer: false,
    }
  },
}
</script>
