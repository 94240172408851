import trackingIcons from './trackingIcons'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    display: {
      type: String,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isConnected: {
      type: Boolean,
      required: true,
    },
    forCarousel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    icon() {
      return trackingIcons[this.type]
    },
  },
}
