<template>
  <router-link class="w-full lg:w-1/2 p-10" :to="`/tracking/${type}`">
    <div
      class="flex items-center border border-grey-300 rounded p-20 cursor-pointer"
    >
      <div
        class="h-45 w-45 rounded-full flex justify-center items-center bg-plum-100"
      >
        <Icon :iconClass="`${icon.type} text-plum-500 flex text-2xl`" />
      </div>
      <div class="pl-10 flex-grow flex items-center">
        <div>
          <h5>{{ value }} {{ units }}</h5>
          <p>{{ display }}</p>
        </div>
        <div class="flex-grow flex justify-end items-center">
          <div
            v-if="isConnected"
            class="p-5 font-bold rounded w-125 text-center bg-blue-500 text-white flex items-center justify-center"
          >
            <Icon iconClass="icon-charger" />
            <span class="ml-5 mb-2 text-sm">{{
              $t('tracking.connected')
            }}</span>
          </div>
          <Icon iconClass="fas fa-chevron-right ml-20" />
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import trackerCardMixin from '@platform-shared/mixins/tracking/trackerCard'

export default {
  mixins: [trackerCardMixin],
}
</script>
