export default {
  steps: {
    bubbleColor: 'bg-orange-100',
    type: 'icon-walking-fast',
    iconColor: 'text-orange-500',
  },
  workouts: {
    bubbleColor: 'bg-sky-100',
    type: 'icon-matches-fire',
    iconColor: 'text-sky-500',
  },
  nutrition: {
    bubbleColor: 'bg-pink-100',
    type: 'icon-apple',
    iconColor: 'text-pink-500',
  },
  sleep: {
    bubbleColor: 'bg-plum-100',
    type: 'icon-smiley-sleepy',
    iconColor: 'text-plum-500',
  },
  bloodPressure: {
    bubbleColor: 'bg-cherry-100',
    type: 'icon-laboratory-test-blood',
    iconColor: 'text-cherry-500',
  },
  weight: {
    bubbleColor: 'bg-lime-100',
    type: 'icon-diet-scale',
    iconColor: 'text-lime-500',
  },
  glucose: {
    bubbleColor: 'bg-yellow-100',
    type: 'icon-laboratory-test-blood-sugar',
    iconColor: 'text-yellow-500',
  },
}
