<template>
  <div
    class="w-screen max-w-lg h-full bg-white overflow-auto border-l flex flex-col"
  >
    <div class="flex items-center p-20 border-b border-grey-200">
      <h4 class="flex-1 mr-100">
        {{
          $t('tracking.history_drawer.title', [
            $t(`tracking.lingo.${tracker.type}.display`),
          ])
        }}
      </h4>
      <button @click="close">
        <CloseIcon />
      </button>
    </div>
    <div class="px-30 pt-20 flex flex-col flex-1 h-0 overflow-y-auto">
      <div class="flex-1">
        <h6 class="text-center">
          {{ $t('tracking.history_drawer.day_span') }}
        </h6>
        <p v-if="!history.length" class="flex-1">
          {{ $t('tracking.history_drawer.no_history') }}
        </p>
        <div
          v-else
          v-for="reading in history"
          :key="reading.date"
          class="flex justify-between py-16 border-b border-grey-200"
        >
          <p class="text-grey-500">{{ reading.date }}</p>
          <p>
            {{ reading.value }} {{ $t(`tracking.lingo.${tracker.type}.units`) }}
          </p>
        </div>
      </div>
    </div>
    <div class="p-20 flex justify-end">
      <HMButton
        rounded="full"
        color="white"
        size="x-small"
        outlined
        class="mr-20"
        @click="close"
      >
        {{ $t('common.close_btn') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import trackerHistoryMixin from '@platform-shared/mixins/tracking/trackerHistory'
import CloseIcon from '@components/common/CloseIcon'

export default {
  components: { CloseIcon },
  mixins: [trackerHistoryMixin],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
