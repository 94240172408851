<template>
  <div class="w-full max-w-4xl py-40 mx-auto">
    <div class="flex justify-between items-center flex-wrap space-y-20">
      <div>
        <h2>{{ $t('tracking.title') }}</h2>
      </div>
      <HMButton rounded="full" @click="openHumanApi">
        {{ $t('tracking.connect_devices') }}
      </HMButton>
    </div>
    <div class="mt-20 flex flex-wrap -m-10">
      <TrackerCard
        v-for="tracker in trackers"
        :key="tracker.type"
        :type="tracker.type"
        :display="$t(`tracking.lingo.${tracker.type}.display`)"
        :units="$t(`tracking.lingo.${tracker.type}.units`)"
        :value="tracker.todaysDisplayValue"
        :isConnected="tracker.isConnected"
      />
    </div>
  </div>
</template>
<script>
import TrackerCard from './TrackerCard'
import openHumanApiMixin from '../../mixins/openHumanApi'
import trackingHomeMixin from '@platform-shared/mixins/tracking/trackingHome'

export default {
  components: { TrackerCard },
  mixins: [openHumanApiMixin, trackingHomeMixin],
}
</script>
